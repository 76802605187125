a {
    text-decoration: none;
    color: #FFFFFF;
}

.App {
    padding-top: 20px;
    padding-right: 5%;
    padding-left: 5%;
}

@media (min-width: 500px)
{
    .App {
        padding-right: 10%;
        padding-left: 10%;
    }
}

@media (min-width: 800px)
{
    .App {
        padding-right: 13%;
        padding-left: 13%;
    }
}

